<template>
  <div class="details">
    <!-- 头部 -->
    <div class="head">
      <!-- 多个回答 -->
      <div class="time">{{ comment }}个回答</div>
    </div>
    <!-- 详情 -->
    <div class="content" v-for="(basic, index) in basiced" :key="index">
      <!-- 上 -->
      <div class="personal">
        <!-- 头像 -->

        <span v-if="basic.img" @click="sees(basic.user)" style="cursor: pointer;">
          <img class="personal_left" :src="basic.img" />
        </span>
        <div class="personal_right">
          <!-- 姓名 -->
          <div class="personal_name" style="cursor: pointer;">
            <span class="personal_name1" @click="sees(basic.user)">
              {{
              basic.name
              }}
            </span>
            <!-- /////////////////////如果是团队发起人 -->
            <span v-if="basic.person == 1">
              <span class="el-icon-user-solid1" style="color:#5a5a5a;font-size: 25px;border:none"></span>
              <span style="font-size: 15px;font-weight: 100;color:#0084FF">发起的团队回答</span>
            </span>
            <span
              v-if="basic.adopt == 1"
              style="  
                      padding:10px;  
                      border-radius: 50%;             
                      border: 1px solid #eca67e;
                      box-shadow: 0px 2px 13px 0px rgba(87, 87, 87, 0.44);
                      font-size: 20px;
                      font-family: Microsoft YaHei;
                      font-weight: 800;
                      font-style: italic;
                      color: #FD6E05;"
            >被采纳</span>
          </div>
          <!-- 个性签名 -->
          <div class="personal_autograph">{{ basic.intro }}</div>
        </div>
      </div>
      <!-- 下 -->
      <div class="basicanswer">
        <!-- 详情 -->
        <!-- style="display: inline-block;overflow: hidden;width: 100%;word-break: keep-all;white-space: nowrap;text-overflow: ellipsis; "  -->
        <!-- <div class="details_content" ref="answer" v-if="visabled==1">{{basic.answer}}</div> -->
        <!-- <div class="details_content" ref="answer" v-if="basic.answer.length>100" >
                 <span class="details_content" v-show="(isExpand)">{{basic.answer}}</span>
                 <span class="details_content" v-show="(!isExpand)">{{basic.answer.substring(0,100)+"..."}}</span>
                 <span v-show="(!isExpand)" style="font-size: 17px;  color: #7a8f9a;  cursor: pointer;" @click="expandThis">展开</span>
                 <span style="font-size: 17px;  color: #7a8f9a;  cursor: pointer;" v-show="(isExpand)" @click="expandThised">收起</span>
        </div>-->
        <!-- 初始化 -->
        <div
          class="details_content"
          ref="answer"
          v-if="basic.answer.length <= 20 && piddd != basic.wdanswerref"
        >
          <span v-if="basic.person == 1">团队说明:</span>
          {{basic.person==1||!anniu? basic.answer: basic.answer.substring(0, basic.answer.length*0.5) + "......" }}
        </div>
        <div
          class="details_content"
          ref="answer"
          v-if="
            basic.answer.length > 20 &&
              basic.answer.length < 50 &&
              piddd != basic.wdanswerref
          "
        >{{ basic.answer.substring(0, basic.answer.length*0.5) + "......" }}</div>
        <div
          class="details_content"
          ref="answer"
          v-if="
            basic.answer.length > 50 &&
              basic.answer.length <= 200 &&
              piddd != basic.wdanswerref
          "
        >{{ basic.answer.substring(0, basic.answer.length*0.5) + "......" }}</div>
        <div
          class="details_content"
          ref="answer"
          v-if="
            basic.answer.length > 200 &&
              basic.answer.length < 500 &&
              piddd != basic.wdanswerref
          "
        >{{ basic.answer.substring(0, 100) + "......" }}</div>
        <div
          class="details_content"
          ref="answer"
          v-if="
            basic.answer.length > 500 &&
              basic.answer.length < 1000 &&
              piddd != basic.wdanswerref
          "
        >{{ basic.answer.substring(0, 300) + "......" }}</div>
        <div
          class="details_content"
          ref="answer"
          v-if="basic.answer.length > 1000 && piddd != basic.wdanswerref"
        >{{ basic.answer.substring(0, 600) + "......" }}</div>
        <!-- 展开 -->
        <div
          class="details_content"
          ref="answer"
          v-if="piddd == basic.wdanswerref && visabled == 1"
        >{{ basic.answer }}</div>
        <div class="open_away" v-if="basic.answer.length > 20">
          <span
            v-show="piddd != basic.wdanswerref && visabled == 1"
            @click="open(basic.wdanswerref)"
            class="open"
          >
            展开
            <!-- <span class="down"></span> -->
          </span>
          <span
            v-show="piddd == basic.wdanswerref && visabled == 1"
            @click="away(basic.wdanswerref)"
            class="away"
          >
            收起
            <!-- <span class="up"></span> -->
          </span>
        </div>
        <!-- 附件 -->
        <span
          style="color:#0084FF;font-size: 15px;cursor: pointer;"
          v-show="visabled == 1&&basic.answerFiles!=API&&basic.answerFiles&&basic.answerFiles!='null'"
          @click="see(basic.answerFiles)"
        >预览附件{{basic.answerFiles}}</span>
            <!-- basic.answerFiles != API + null &&
            basic.answerFiles != API + undefined &&
            basic.answerFiles != API -->
        <!-- 图片显示 -->
        <img
          v-if="basic.imge != API && visabled == 1 && basic.imge != API + null&&basic.imge !=  null"
          width="150px"
          height="120px"
          :src="basic.imge"
          alt
          style=" margin-right:10px"
        />
        <!-- 视频显示 -->
        <video
          v-if="
            basic.videos != API + undefined &&
              basic.videos != API + null &&
              visabled == 1 &&
              basic.videos != API&&basic.videos !=  null
          "
          :src="basic.videos"
          controls="controls"
          width="200px"
          height="120px"
          style="border :1px solid rgba(220,220,220,1)"
        >您的浏览器不支持 video 标签。</video>
        <!-------------------------- 多人回答就展示 ----------------------------->
        <div v-if="basic.person == 1 && visabled == 1">
          <div class="Multiple" v-for="son in basic.mediaPathArray" :key="son.id">
            <!-- 头像 -->
            <img class="personal_lefts" :src="son.img" />
            <!-- 姓名 -->
            <div class="Multiple_right">
              <span class="personal_nameD" @click="sees(son.usersid)">
                {{
                son.name
                }}
              </span>
              <span class="personal_autographs">{{ son.intro }}</span>
            </div>
            <span v-if="SonVisabal != son.id">
              <span
                class="details_content"
                v-if="son.answer_content.length <= 100"
              >{{ son.answer_content }}</span>
              <span
                class="details_content"
                v-if="
                  son.answer_content.length > 100 &&
                    son.answer_content.length <= 300
                "
              >{{ son.answer_content.substring(0, 80) + "......" }}</span>
              <span
                class="details_content"
                v-if="
                  son.answer_content.length > 300 &&
                    son.answer_content.length < 500
                "
              >{{ son.answer_content.substring(0, 200) + "......" }}</span>
              <span
                class="details_content"
                v-if="
                  son.answer_content.length > 500 &&
                    son.answer_content.length < 1000
                "
              >{{ son.answer_content.substring(0, 300) + "......" }}</span>
              <span
                class="details_content"
                v-if="son.answer_content.length > 1000"
              >{{ son.answer_content.substring(0, 600) + "......" }}</span>
            </span>
            <!-- 展开收起 -->
            <span class="details_content" v-if="SonVisabal == son.id">
              {{
              son.answer_content
              }}
            </span>
            <span v-if="son.answer_content.length > 100">
              <span v-if="SonVisabal != son.id" @click="sonDK(son.id)" class="open">展开</span>
              <span v-if="SonVisabal == son.id" @click="sonSQ()" class="away">收起</span>
            </span>
            <!-- <span class="bianjis">{{son.submit_time}}</span> -->
            <!-- 附件地址 -->
            <div
              style="color:#0084FF;font-size: 15px;cursor: pointer;"
              v-if="son.attachment_url&&son.attachment_url!='null'"
              @click="see(son.attachment_url)"
            >预览附件</div>
          </div>
        </div>
        <!-- 编辑时间 -->
        <div class="edit" v-if="visabled == 1">
          编辑于
          <span class="bianji">{{ basic.createtimes }}</span>
        </div>
        <!-- 图标 -->
        <div class="bottom" v-if="visabled == 1">
          <!-- 赞 -->
          <div class="iconed" @click="fabuloused(index, basic.wdanswerref)">
            <!-- {{basic.charge}}     -->
            <!-- 未赞 -->
            <span class="refresh-icon1" v-if="basic.charge == 'f'"></span>
            <!-- 已赞 -->
            <span class="refresh1-icon1" v-if="basic.charge == 't' && basic.fabuloused != 0"></span>
            <span>赞</span>
            <span>{{ basic.fabuloused }}</span>
          </div>
          <!-- 评论 -->
          <div class="icon" @click="answereds(index, basic.wdanswerref)">
            <span class="refresh-icon2"></span>
            <span>评论</span>
            <span>{{ basic.answers }}</span>
          </div>
          <!-- 删除回答 -->
          <div
            class="icons"
            v-if="basic.user == userID && basic.adopt != 1"
            @click="deletes(basic.wdanswerref)"
          >
            <span class="refresh-icon3"></span>
            <span class="delete">删除回答</span>
          </div>
          <div class="texts" style="display:none;">
            <textarea
              maxlength="5000"
              v-model="commentseds"
              id="search-icons"
              class="search-icon"
              placeholder="写下你的评论："
            ></textarea>
            <span class="search-bar" @click="comments()">发表评论</span>
          </div>
          <div class="words" v-if="visabled == 1">
            <ul class="comment-say" v-if="basic.replyList">
              <!-- 显示一级评论 -->
              <li
                class="comment-say-li clr comment-level-1"
                v-for="(onepcomment, ida) in basic.replyList"
                :key="onepcomment.id"
              >
                <div class="csl-img">
                  <a rel="nofollow">
                    <img
                      v-if="onepcomment.authorImg"
                      :src="onepcomment.authorImg"
                      width="50"
                      height="50"
                      style="  border-radius: 50%;cursor: pointer;"
                      @click="sees(onepcomment.authorId)"
                    />
                  </a>
                </div>
                <div class="csl-body">
                  <div class="cont">
                    <div class="row clr">
                      <a
                        @click="sees(onepcomment.authorId)"
                        rel="nofollow"
                        class="name replyName"
                      >{{ onepcomment.authorName }}</a>
                      <span class="times">{{ onepcomment.time }}</span>
                    </div>
                    <div class="des">{{ onepcomment.content }}</div>
                  </div>
                  <div class="opBtn">
                    <a
                      href="javascript:;"
                      @click="
                        writeBack(
                          basic.wdanswerref,
                          onepcomment.commentator,
                          onepcomment.id,
                          onepcomment.id
                        )
                      "
                      class="respond-coin"
                      title="回复"
                    >
                      <i />
                      <em>回复</em>
                    </a>
                    <span class="zan">
                      <i />
                      <!-- <em class="z-count">									({{onepcomment.praiseNum}})								</em> -->
                    </span>
                  </div>
                  <!-- 二次评论 -->
                  <div class="respond-submit" v-if="pid == onepcomment.id">
                    <div class="texteds">
                      <input
                        type="text"
                        v-model="content1"
                        style="width: 100%;padding-left:80px ; box-sizing: border-box;margin-bottom:10px"
                      />
                      <div class="tip">
                        回复
                        <!-- <a>{{onepcomment.authorName}}</a> -->
                        ：
                      </div>
                    </div>
                    <div class="sub clr">
                      <button @click="sendChildComment(basic, index)">发表评论</button>
                      <div class="qqFace" style="position:absolute; display:none; z-index:10"></div>
                    </div>
                  </div>
                  <ul class="csl-respond">
                    <!-- 显示二级评论 -->
                    <li
                      class="comment-respond-li clr comment-level-2"
                      v-for="onecomment in basic.replyList[ida].children"
                      v-show="!basic.replyList.children"
                      :key="onecomment.id"
                    >
                      <div class="crl-img">
                        <a rel="nofollow">
                          <!-- <img src="https://www.leiphone.com/uploads/new/avatar/05/21/89/61_avatar_pic_40_40.jpg?updateTime=1" width="45" height="45"> -->
                        </a>
                      </div>
                      <div class="crl-body">
                        <div class="cont">
                          <!-- <img v-if="onecomment.UserauthorImg" :src="onecomment.UserauthorImg" width="40" height="40" style=" position: relative;top:10px;border-radius: 50%;margin-right:5px"> -->
                          <a
                            @click="sees(onecomment.UserauthorId)"
                            class="name replyName"
                            target="_blank"
                          >{{ onecomment.UserauthorName }}</a>
                          <span style="color:#8B8A8A;">回复</span>
                          <!-- <img v-if="onecomment.authorImg" :src="onecomment.authorImg" style="position: relative;top:10px;border-radius: 50%;margin-right:5px" width="40" height="40">							 -->
                          <a
                            @click="sees(onecomment.authorId)"
                            class="name toReplyName"
                            target="_blank"
                          >{{ onecomment.authorName }}</a>：
                          <span class="des">{{ onecomment.content }}</span>
                        </div>
                        <div class="opBtn">
                          <a
                            href="javascript:;"
                            @click.stop="
                              writeBacks(
                                basic.wdanswerref,
                                onepcomment.commentator,
                                onecomment.id,
                                onepcomment.id
                              )
                            "
                            class="respond-coin"
                            title="回复"
                          >
                            <i />
                            <em>回复</em>
                          </a>
                          <span class="zan">
                            <i />
                            <!-- <em class="z-count">({{onecomment.praiseNum}})								</em> -->
                          </span>
                        </div>
                        <!-- 三次评论 -->
                        <div class="respond-submit" style v-if="pic == onecomment.id">
                          <input
                            v-model="content2"
                            placeholder="回复："
                            type="text"
                            id="answer"
                            style="
                            width:98%;
                            height:20px;
                            border-radius: 2px;  
                            padding-right: 10px;height: 30px;
                            line-height: 14px;
                            font-size: 14px;
                            line-height: 37px\9;
                            color: #5a5a5a;
                            border: solid 1px #eeeeee;
                            background: #f7f7f7;"
                          />
                          <div class="sub clr">
                            <!-- <span class="emotionc">表情</span> -->
                            <button
                              @click="
                                sendChildComments(onecomment.UserauthorId)
                              "
                            >发表评论</button>
                            <div class="qqFace" style="position:absolute; display:none; z-index:10"></div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PicturP from "@/views/wisdom-answer/answerDetail/hotspot-details/picture-enlargement/index.vue";
import { DELETE, QUERY, INSERT, UPDATE } from "@/services/dao.js";
import {
  updatedownload,
  formatZero,
  getTimeFormat
} from "@/js/common/Resoures";
import axios from "axios";

import { dataFormat, AcceptNum } from "@/js/common/index.js";
export default {
  data() {
    return {
      centersED: "", //敏感词过滤中间转换
      SonVisabal: -1, ////多人问答的展开收起
      showImg: false,
      imgSrc: "",
      // comment:"",
      commentseds: "", //评论
      publisheed: "",
      CommtCommtESD: "",
      visable: false,
      // fabul:"",
      ID: 0,
      index: 0,
      pid: -1,
      pic: -1,
      piddd: -1, //展开收起
      content1: "", //二级评论的输入内容
      content2: "",
      // visabled:0,///显示按钮
      visabledss: true, ///显示带省略号的内容
      isExpand: false, //是否显示展开
      API: ""
      //  replyList: [],//显示一级评论
      // answers:"",
      // num:{
      //     num1:0
      // }
      // basic:{
      //     personal_left:require('../../../../assets/icon/矩形 6.png'),
      //     napersonal_nameme:'段浩天',
      //     personal_autograph:'常将有日思无日 莫待无时思有时',
      //     details_content:'五角大楼发布三段UFO视频，称其速度奇快，又有许多网友一片惊呼，UFO真的被证实了！其实，这些朋友还是没有弄清楚“UFO”是什么意思。如果弄清楚了，就知道被称为“UFO”就是没有弄清的事情。因为UFO就是不明飞行物的简称，英文名全称为Unidentified Flying Object，缩写为UFO。现在知道了吧？如果弄清楚了就不叫UFO了，而是明确的指出某天发现的不明飞行物是什么什么了。就如同原来许多网友发现的UFO现象，军方或者某航天基地出来说明，是某月某日发射火箭形成的羽状尾流现象等等。其实，4月27日美国国防部在美国海军网站上发布的所谓三个UFO视频，都是在炒十几年前的剩饭，毫无新意。这三个视频其中一段摄于2004年11月，另外两段摄于2015年1月。这些视频毫无秘密可言，一直都在公共网络传播。',
      //     url:require('../../../../assets/icon/矩形 6.png'),
      // }
    };
  },

  props: {
    basiced: {
      type: Array,
      required: true
    },
    sons: {
      type: Array,
      required: true
    },
    // children:{
    //   type:Array,
    //   required:true
    // },
    userID: {
      type: Number,
      required: true
    },
    userID: {
      type: Number,
      required: true
    },
    CommentContent: {
      type: Array,
      required: true
    },
    CommentComment: {
      type: Array,
      required: true
    },
    // fabuls:{
    //     type:Number,
    //     required:true
    // },
    comment: {
      type: Number,
      required: true
    },
    visabled: {
      type: Number,
      required: true
    },
    anniu: { type: null, required: true }
    // api:{
    //          type:String,
    //    required:true
    // }

    // replyList:{
    //      type:Array,
    //     required:true
    // }
  },
  components: {},

  created() {
    this.API = this.api.LoginURL.concat();
  },

  methods: {
    ///敏感词过滤
    async www(name) {
      await axios
        .post(
          "http://39.100.48.36:3016/getData",
          {
            content: name
          },
          {
            headers: {
              "Content-Type": "application/json"
            }
          }
        )
        .then(response => {
          // console.log("敏感词过滤："+JSON.stringify(response.data.message.filterContent))
          this.centersED = response.data.message.filterContent;
          console.log("赋值成功：" + this.centersED);
        })
        .catch(function(error) {
          console.log("错误" + error);
        });
    },
    async expandThis() {
      this.isExpand = !this.isExpand;
    },
    async expandThised() {
      this.isExpand = !this.isExpand;
    },
    open(id) {
      this.piddd = id;
    },
    async sonDK(id) {
      this.SonVisabal = id;
    },
    async sonSQ() {
      this.SonVisabal = -1;
    },
    away(id) {
      this.piddd = 0;
    },
    //  async quanbu(){
    //   let user=this.$store.state.userinfo.id;
    //     if(this.sharedtype!=1)
    //     {///如果收费
    //       if(user=="" || user==null || user<0)
    //       {
    //         alert('请您先登录！')
    //       }
    //       else{
    //         ///判断是否已经购买
    //          let ddata=await QUERY("post","",'  WDALLBill(where: {userid: {_eq: '+user+'}, orderid: {_eq: '+this.problemid+'}}) {  time  orderNum  id   }')
    //               if(ddata.data.WDALLBill.length>0)
    //              {////如果已经购买
    //                      this.$message({
    //                          type:"success",
    //                          message:"您已经购买过！请直接查看！"
    //                       })
    //                     ////显示全部问题
    //                           this.anniu=false;
    //                           this.visabled=true;
    //                           this.visabledss=false;
    //                           this.$emit('quanbu');
    //               }
    //           else{
    //           // 如果没有购买
    //         ///根据登陆账号查找账户余额
    //         let data=await QUERY("post","",'  PCUsers(where: {id: {_eq: '+user+'}}) {   Money  }')
    //           this.$confirm("您的账号知识币余额为"+data.data.PCUsers[0].Money+"，您确定要支付"+this.sharedpay+"知识币查看答案吗？","系统提示",{
    //                   confirmButtonText:"确定",
    //                   cancelButtonText:"取消",
    //                   type:"warning"
    //                 })
    //                 ///确认按钮
    //                 .then(()=>{

    //                     // this.purchase(user,data.data.PCUsers[0].Money);//同意购买
    //                     //     this.anniu=false;
    //                     //     this.visabled=true;
    //                     //     this.visabledss=false;
    //                     //     this.$emit('quanbu');
    //                        this.AcceptNumed();//被采纳的回答采纳数修改
    //                       //  this.UserAnswer();//该被采纳者的采纳数、回答数统计
    //                 })
    //                 // 取消按钮
    //                 .catch(() =>{
    //                       console.log("取消订单！")

    //                 })
    //       }
    //       }

    //     }
    //     else if(this.sharedtype==1)
    //     {///如果免费
    //     this.anniu=false;
    //     this.visabled=true;
    //     this.visabledss=false;
    //     this.$emit('quanbu');
    //     }

    // },

    //查看团队成员的回答列表
    async sees(id) {
      //alert(id)
      this.$router.push({
        path: "/answer-list",
        query: {
          id: id
        }
      });
    },
    clickImg(e) {
      this.showImg = true;
      // 获取当前图片地址
      this.imgSrc = e.currentTarget.src;
    },
    viewImg() {
      this.showImg = false;
    },
    //   //显示回答个数
    //   async answer(){
    //        this.problemid=this.$route.query.id;
    //        let totals=  await  QUERY("post","",'    WDUserQuestionAnswerRef_aggregate(where: {wdquestionid: {_eq: '+this.problemid+'}}) { aggregate {   count   } }');
    //        this.comment=totals.data.WDUserQuestionAnswerRef_aggregate.aggregate.count;
    //   },
    ///删除回答
    async deletes(id) {
      //alert(id)
      // WDUserQuestionAnswerRef(where: {wdanswerref: {_eq: 33}}) {  user  }
      //获取登陆用户ID
      //  this.user=this.$store.state.userinfo.id;
      //    let totals=  await  DELETE("post","",' delete_WdAnswerRef(where: {id: {_eq: '+id+'}}) {    affected_rows  }');
      //    if(totals.data.delete_WdAnswerRef.affected_rows>0)
      //    {
      //            this.$message({
      //               message: "删除成功！",
      //               type: "success",
      //             });
      //    }
      //alert(totals.data.WDUserQuestionAnswerRef[0].user)
      this.$emit("deletesed", id);
    },
    //显示评论
    //     async inits() {
    //         let info=null;
    //       //显示一级评论
    //       this.replyList.length = 0;
    //     //alert(JSON.stringify( this.basiced[0].id))
    //     for(let i = 0; i < this.basiced.length; i++)
    //     {
    //         //根据回答ID查询一级评论
    //          info=await QUERY("post","",'   WDOneComment(where: {whole: {_eq: '+this.basiced[i].id +'}}) {      ancestorCommentId  answer   authorId   answers    authorImg   authorName   commentator   content    fabulous   id    whole    user   type   time   question parentCommentId  imge  isShield }');
    //       for (let j = 0; j < info.data.WDOneComment.length; j++) {
    //         let a = info.data.WDOneComment[j];
    //         // 显示二级评论
    //     //    let info_c=await QUERY("post","",'   TTTwoComment(where: {whole: {_eq: '+this.articleId+'}, ancestorCommentId: {_eq: '+a.id+'}}) {  type    time   parentCommentId   isShield  id   content   commentator  authorName   authorImg   authorId   articleintro   articleTitle   articleImg  ancestorCommentId  UserauthorImg   UserauthorId   UserauthorName }');
    //     //     a.children = info_c.data.TTTwoComment;
    //         this.replyList.push(a);
    //       }
    // }
    //     },
    ////评论
    async answereds(index, id) {
      let sub_menu = this.$el.querySelectorAll(".texts");
      for (let j = 0, len = sub_menu.length; j < len; j++) {
        if (index == j) {
          if (sub_menu[j].style.display == "none") {
            sub_menu[j].style.display = "block";
          } else {
            sub_menu[j].style.display = "none";
          }
        } else if (sub_menu[j].style.display == "block") {
          sub_menu[j].style.display = "none";
        }
      }
      this.ID = id;
      this.index = index;
    },
    ///回答评论
    async commenting(pid) {
      this.pid = pid;
    },
    //回答评论的回答
    async commentings(picd) {
      this.picd = picd;
    },
    //点赞功能
    async fabuloused(e, id) {
      //获取登陆用户ID
      // this.user=this.$store.state.userinfo.id;
      //通过用户的ID和回答的ID查询是否点赞
      //  let totals=  await  QUERY("post","",'  WDPraise(where: {user: {_eq: '+this.user+'}, praiseMan: {_eq: '+id+'}}) {id  }');
      //   this.fabul=totals.data.WDPraise.length;
      // let num=document.getElementById("NumD").value;
      this.$emit("fabuloused", e, id);
    },
    //   //显示点赞状态
    //   async fabulousee(){
    //     //    let totals=  await  QUERY("post","",'  WDPraise(where: {user: {_eq: '+this.user+'}, praiseMan: {_eq: '+id+'}}) {id  }');
    //     //    this.fabul=totals.data.WDPraise.length;
    //     //alert(basic.id)
    //   },

    //////////////////一级评论//////////////////
    async comments() {
      await this.www(this.commentseds); //敏感词过滤
      this.commentseds = this.centersED; //赋值
      // 发送事件
      this.$emit("commenteds", this.commentseds, this.ID, this.index);
      this.commentseds = "";
    },
    //////////////////////二级评论////////////////////
    async sendChildComment(basic, index) {
      await this.www(this.content1); //敏感词过滤
      this.content1 = this.centersED; //赋值
      this.$emit(
        "sendChildComment",
        this.parentId,
        this.content1,
        this.ID,
        index,
        this.ancestorId
      );
      this.content1 = "";
      this.pic = -1;
      this.pid = -1;
    },
    //////////////////三级评论////////////////////
    async sendChildComments(UserauthorId) {
      await this.www(this.content2); //敏感词过滤
      this.content2 = this.centersED; //赋值
      this.$emit(
        "sendChildComments",
        this.parentId,
        this.content2,
        this.ID,
        this.ancestorId,
        UserauthorId
      );
      this.content2 = "";
      this.pic = -1;
      this.pid = -1;
    },
    ////下载附件
    async see(answerFiles) {
      console.log(answerFiles);
      //  window.URL.createObjectURL=answerFiles;
      //  window.location.href =this.api.LoginURL.concat( answerFiles);
      window.open(answerFiles, "_blank"); ///新建窗口
    },
    async writeBack(id, parentId, pid, ancestorCommentId) {
      this.pid = pid;
      this.pic = -1;
      this.parentId = parentId;
      this.ID = id;
      this.ancestorId = ancestorCommentId;
      //alert(this.pid)
    },
    async writeBacks(id, parentId, pic, ancestorCommentId) {
      this.pic = pic;
      this.pid = -1;
      this.parentId = parentId;
      this.ID = id;
      this.ancestorId = ancestorCommentId;
      //alert(this.pic)
    }
    //     //显示回答个数
    // async answerwww(){
    //      this.problemid=this.$route.query.id;
    //     ///查询是否免费
    //     let data=  await   QUERY("post","",'  WDNewQuestion(where: {id: {_eq: '+this.problemid+'}}) { competeMode }');
    //      ///查询问题的回答个数
    //    let totals=  await  QUERY("post","",'    WDUserQuestionAnswerRef_aggregate(where: {wdquestionid: {_eq: '+this.problemid+'}}) { aggregate {   count   } }');
    //     //  判断是否是收费
    //     // if(this.sharedtype==1){///免费
    //     //   this.comment=totals.data.WDUserQuestionAnswerRef_aggregate.aggregate.count;
    //     // }
    //     // else{///收费
    //     // 判断是否是免费
    //     if(data.data.WDNewQuestion[0].competeMode==1&&totals.data.WDUserQuestionAnswerRef_aggregate.aggregate.count!=0){//是免费
    //        this.anniu=true;
    //     }
    //     else{
    //     //  查询是否被采纳
    //       let dopt=await QUERY("POST","",'  WDUserQuestionAnswerRef(where: {wdquestionid: {_eq: '+this.problemid+'}, adopt: {_eq: 1}}) {  wdanswerref  }')
    //      if(dopt.data.WDUserQuestionAnswerRef.length>0)
    //      {///如果被采纳
    //      let totals=  await  QUERY("post","",'    WDUserQuestionAnswerRef_aggregate(where: {wdquestionid: {_eq: '+this.problemid+'}}) { aggregate {   count   } }');
    //      this.comment=totals.data.WDUserQuestionAnswerRef_aggregate.aggregate.count;

    //      if(this.comment==0){
    //         //  this.visabled=true;
    //          this.visabledss=true;
    //          this.anniu=false;
    //      }
    //      else{
    //          this.anniu=true;
    //      }
    //    }
    //    else{
    //      this.comment=0;
    //     this.visabledss=true;
    //     this.anniu=false;

    //    }
    //     }
    //     // }
    // },
    //评论评论的请求
    //     async publishCommt(wdcommentid,basicid){
    //         //alert(basicid)
    //    this.$emit('publish',this.CommtCommtESD,wdcommentid,basicid);
    //     }
  }
  // watch(){},
};
</script>
<style scoped>
.details {
  width: 100%;
  /* border: 1px solid red; */
  /* height: 1100px; */
  margin: auto;
  /* position: absolute;  */
  display: inline-block;
  /* border:1px solid #c0c0c0 */
  /* margin-left: 20px; */
}
.head {
  width: 96%;
  height: 30px;
  float: left;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #c0c0c0;
  margin-left: 17px;
}
.answer {
  /* font-weight: bold; */
  font-size: 12px;
  float: right;
}
.time {
  font-size: 15px;
  font-weight: bold;
}
.content {
  width: 95%;
  margin-left: 20px;
  /* border:1px solid yellow */
}
.personal {
  width: 100%;
  /* padding-top: 20px; */
  /* border: 1px solid green;  */
  display: inline-block;
  /* margin-top: 20px; */
}
.personal_left {
  width: 70px;
  height: 70px;
  float: left;
  margin-top: 10px;
  border-radius: 50%;
  /* border: 1px solid red; */
  /* display: inline-block; */
}
.personal_lefts {
  width: 50px;
  height: 50px;
  /* float: left; */
  /* margin-top: 10px; */
  border-radius: 50%;
  /* border: 1px solid red; */
  display: inline-block;
  /* position: relative;
top: 500px; */
}
.Multiple_right {
  width: 90%;
  /* border: 1px solid darkmagenta; */
  display: inline-block;
  vertical-align: top;
  margin-left: 5px;
}
.personal_right {
  float: left;
  width: 610px;
  height: 70px;
  /* border:1px solid red; */
  line-height: 25px;
  padding-top: 10px;
  margin-top: 10px;
  padding-left: 10px;
}
.personal_name {
  font-weight: bold;
  font-size: 15px;
}
.personal_name1:hover {
  text-decoration: underline;
}
.personal_nameD:hover {
  text-decoration: underline;
}
.personal_autograph {
  font-size: 12px;
  color: #c0c0c0;
}
.personal_autographs {
  font-size: 11px;
  color: #c0c0c0;
  line-height: 25px;
}
.details_content {
  font-size: 13px;
  line-height: 25px;
  width: 100%;
  word-wrap: break-word;
}
.details_contents {
  font-size: 13px;
  line-height: 25px;
  /* width: 100%; */
  word-wrap: break-word;
  vertical-align: top;
  /* position: relative;
  top: 20px; */
  /* display: inline-block; */
}
.details_img {
  width: 60px;
  height: 60px;
  margin-top: 10px;
  margin-right: 6px;
}
.edit {
  width: 100%;
  height: 70px;
  line-height: 50px;
  font-size: 14px;
}
.bottom {
  width: 98%;
  line-height: 30px;
  height: 3s0px;
  /* border:1px solid fuchsia; */
  border-bottom: 1px solid gainsboro;
  padding-bottom: 30px;
  display: inline-block;
}
.bottom > div {
  float: left;
  margin-right: 30px;
}
.icon > span,
.iconed > span {
  width: 20px;
  height: 20px;
  /* border:1px solid yellowgreen; */
  /* display: inline-block; */
  /* margin-top: 3px; */
  cursor: pointer;
}
.icon,
.iconed {
  cursor: pointer;
}
.iconed:hover {
  color: #0084ff;
}
.icons {
  cursor: pointer;
}
.icons > span {
  width: 20px;
  height: 20px;
  /* border:1px solid yellowgreen; */
  /* display: inline-block; */
  /* margin-top: 3px; */
}
.bottom {
  /* text-align: right; */
  font-size: 13px;
  /* padding-right: 20px; */
  /* margin-right: 10px; */
}
.bianji {
  margin-left: 5px;
  color: gray;
  font-size: 13px;
}
.bianjis {
  margin-left: 20px;
  color: gray;
  font-size: 11px;
}
/* 图标 */

.refresh-icon1 {
  width: 30px;
  height: 30px;
  display: inline-block;
  background-image: url("../../../../assets/icon/Fabulous.png");
  background-size: 16px 16px;
  background-repeat: no-repeat;
  position: relative;
  top: 7px;
  left: 5px;
  margin-right: 5px;
}
.refresh1-icon1 {
  width: 30px;
  height: 30px;
  display: inline-block;
  background-image: url("../../../../assets/icon/Fabuloused1.png");
  background-repeat: no-repeat;
  background-size: 16px 16px;
  position: relative;
  top: 7px;
  left: 5px;
  margin-right: 5px;
}
.refresh-icon2 {
  width: 20px;
  height: 20px;
  display: inline-block;
  background-image: url("../../../../assets/icon/comment.png");
  background-repeat: no-repeat;
  background-size: 16px 16px;
  position: relative;
  top: 7px;
  margin-right: 5px;
  /* border:1px solid red; */
  cursor: pointer;
}
.refresh-icon3 {
  width: 15px;
  height: 15px;
  display: inline-block;
  background-image: url("../../../../assets/icon/delete.png");
  background-repeat: no-repeat;
  background-size: 16px 16px;
  position: relative;
  top: 7px;
  /* margin-right: 5px; */
  /* border:1px solid red; */
  cursor: pointer;
}
.delete {
  font-size: 14px;
  color: darkgrey;
}
.refresh-icon4 {
  width: 20px;
  height: 20px;
  display: inline-block;
  background-image: url("../../../../assets/icon/share1.png");
  background-repeat: no-repeat;
  position: relative;
  background-size: 16px 16px;
  top: 7px;
  left: 5px;
}
.tu {
  width: 20px;
  height: 20px;
  display: inline-block;
  background-image: url("../../../../assets/icon/down.png");
  background-repeat: no-repeat;
  position: relative;
  top: 10px;
  left: 5px;
}
.open {
  width: 100%;
  /* border:1px solid red; */
  text-align: center;
  color: #0084ff;
  font-size: 13px;
  cursor: pointer;
}
.down {
  width: 25px;
  height: 25px;
  display: inline-block;
  background-image: url("../../../../assets/icon/down2.png");
  background-repeat: no-repeat;
  position: relative;
  top: 15px;
  left: 2px;
}
.texts {
  width: 100%;

  padding: 0;
}
.search-icon {
  text-align: left;
  margin-top: 10px;
  width: 100%;
  height: 100px;
  border: 1px solid #767676;
  border-radius: 6px;
  display: inline-block;
  color: black;
  font-size: 15px;
  background: #f8f5f5;
  position: relative;
  resize: none;
}
.search-bar {
  color: white;
  width: 100px;
  height: 40px;
  background-color: #0084ff;
  /* display: inline-block; */
  font-size: 15px;
  line-height: 40px;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
  float: right;
}
.search-bars {
  color: white;
  width: 80px;
  height: 30px;
  background-color: #0084ff;
  display: inline-block;
  font-size: 13px;
  line-height: 30px;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 6px;
}
.commented {
  width: 100%;
  /* padding: 20px; */
  display: inline-block;
  text-align: left;
  /* background: rgb(248, 248, 247); */

  /* border: 1px solid red; */
}
.comment_name {
  color: #09a25f;
  font-weight: bold;
  font-size: 15px;
  font-family: Microsoft YaHei;
}
.comment_content {
  font-family: Microsoft YaHei;
  font-size: 12px;
}
.commentleft {
  width: 95%;
  background: rgb(248, 248, 247);
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
}
.commentright {
  width: 100%;
  /* border: 1px solid red; */
}
.hiufu {
  /* border: 1px solid red ; */
  width: 98%;
  font-size: 15px;
  color: rgb(124, 121, 121);
  text-align: right;
  padding-right: 10px;
  cursor: pointer;
}
.texted {
  position: relative;
  width: 100%;
  /* border: 1px solid tomato; */
  text-align: right;
  /* display: none; */
}
.texteds {
  position: relative;
  width: 100%;
  /* border: 1px solid tomato; */
  text-align: right;
  /* display: none; */
}
.tip {
  font-size: 14px;
  color: #999999;
  position: absolute;
  top: 3px;
  left: 4px;
}
.tip a {
  padding: 0 5px;
}

.comment {
  height: 200px;
}

.csl-img {
  display: inline-block;
  width: 8%;
  /* height: 40px;
    border-radius: 50%; */
  /* border: 1px solid red; */
  vertical-align: top;
}
.csl-body {
  width: 90%;
  /* border: 1px solid forestgreen; */
  display: inline-block;
}
.opBtn {
  width: 5%;
  display: inline-block;
  text-align: right;
  /* border: 1px solid #0084FF; */
  font-size: 12px;
  color: #cccccc;
  height: 14px;
  line-height: 14px;
  font-style: inherit !important;
}
.opBtn a.respond-coin i {
  display: inline-block;
  /* background: url(../../images/common/yp_spirit.png) no-repeat -130px -270px; */
  /* width: 14px;
  height: 13px; */
}
.opBtn a.respond-coin em {
  color: #aaaaaa;
  font-size: 12px;
  position: relative;
  top: -2px;
  /* margin-left: 5px; */
}
.opBtn .zan {
  display: inline-block;
  position: relative;
  /* top: -2px; */
}
.cont {
  width: 94%;
  display: inline-block;
  /* border: 1px solid goldenrod; */
}
.times {
  color: #aaaaaa;
}
.words {
  padding-bottom: 0;
  width: 100%;
  /* border: 1px solid red; */
}
.words h3 {
  font-size: 14px;
  color: #999999;
  height: 35px;
  line-height: 35px;
  border-bottom: solid 1px #e8e8e8;
  position: relative;
  margin-top: 4px;
  font-weight: normal;
}
.words h3 strong {
  font-size: 16px;
  color: #738a9a;
  position: absolute;
  bottom: -1px;
  left: 0;
}
ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}

.clr {
  zoom: 1;
}
li {
  list-style: none;
}
li {
  display: list-item;
  text-align: -webkit-match-parent;
}
.clr:after {
  clear: both;
  content: "\0020";
  display: block;
  visibility: hidden;
  height: 0;
}

a {
  text-decoration: none;
  color: #333;
  border: 0;
  font-size: 100%;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
}
a:-webkit-any-link {
  cursor: pointer;
}

img {
  border: 0;
}
.texteds input {
  border-radius: 2px;
  padding-right: 10px;
  height: 30px;
  line-height: 14px;
  font-size: 14px;
  line-height: 37px\9;
  color: #5a5a5a;
  border: solid 1px #eeeeee;
  background: #f7f7f7;
}
button {
  -webkit-appearance: button;
  -webkit-writing-mode: horizontal-tb !important;
  text-rendering: auto;
  color: buttontext;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  display: inline-block;
  text-align: center;
  align-items: flex-start;
  cursor: default;
  background-color: buttonface;
  box-sizing: border-box;
  margin: 0em;
  font: 400 13.3333px Arial;
  padding: 1px 6px;
  border-width: 2px;
  border-style: outset;
  border-color: buttonface;
  border-image: initial;
}
.csl-body .sub button {
  float: right;
  font-family: Microsoft Yahei;
  width: 100px;
  height: 40px;
  line-height: 40px;
  right: 0;
  top: 48px;
  text-align: center;
  background: #0084ff;
  color: #fff;
  font-size: 14px;
  border-radius: 5px;
  border: 0;
  cursor: pointer;
}
.basicanswer {
  /* border: 1px solid  red; */
}
.name:hover {
  text-decoration: underline;
  cursor: pointer;
}
.des {
  word-wrap: break-word;
  /* border: 1px solid tomato; */
}
.open {
  /* font-size: 13px; */
  cursor: pointer;
  font-size: 13px;
  font-family: Microsoft YaHei;
  font-weight: 300;
  color: #0084ff;
}
.away {
  font-size: 13px;
  font-family: Microsoft YaHei;
  font-weight: 300;
  color: #686868;
  cursor: pointer;
}
textarea:focus {
  outline: none;
  border: 1px solid #9ac1f5;
  box-shadow: 1px 2px 13px 0px rgba(11, 97, 226, 0.44);
  /* border-width :0 0 1px 0 ; */
}
input:focus {
  outline: none;
  border: 1px solid #9ac1f5;
  box-shadow: 1px 2px 13px 0px rgba(11, 97, 226, 0.44);
  /* border-width :0 0 1px 0 ; */
}
.down {
  width: 15px;
  height: 15px;
  display: inline-block;
  background-image: url("../../../../assets/icon/down2.png");
  background-repeat: no-repeat;
  background-size: 10px 6px;
  position: relative;
  top: 10px;
  left: 2px;
  /* border: 1px solid red; */
}
.up {
  /* Up.png */
  width: 15px;
  height: 15px;
  display: inline-block;
  background-image: url("../../../../assets/Up.png");
  background-repeat: no-repeat;
  position: relative;
  top: 4px;
  left: 2px;
  background-size: 10px 10px;
}
.open_away {
  widows: 100%;
  /* border: 1px solid red; */
  line-height: 20px;
  text-align: right;
}
.Multiple {
  width: 90%;
  padding-left: 10%;
  display: inline-block;
  /* float: right; */
  /* border: 1px solid red; */
  /* position: relative; */
  margin-right: 0;
  clear: both;
  vertical-align: top;
  margin-bottom: 20px;
  /* padding-top: 20px; */
}
.personal_nameD {
  width: 100%;
  vertical-align: top;
  font-weight: bold;
  line-height: 25px;
  font-size: 15px;
  /* margin-left: 5px; */
  /* line-height: 50px; */
  /* border: 1px solid green; */
  display: inline-block;
  cursor: pointer;
  /* position: relative;
  top: 20px; */
}
.el-icon-user-solid1 {
  background-image: url("~@/assets/Qunliao01(1).png");
  display: inline-block;
  width: 23px;
  height: 23px;
  background-size: 100% 100%;
  position: relative;
  top: 7px;
}
</style>
