<template>
    <!-- 过渡动画 -->
  <transition name="fade">
    <div class="img-view" @click="bigImg">
      <!-- 遮罩层 -->
      <div class="img-layer"></div>
      <div class="img">
        <img :src="imgSrc">
      </div>
    </div>
  </transition>
</template>
<script>
  export default {
    props: ['imgSrc'],
    methods: {
      bigImg() {
        // 发送事件
        this.$emit('clickit')
      }
    }
  }
</script>
<style scoped>
  /*动画*/
  .fade-enter-active,
  .fade-leave-active {
    transition: all .2s linear;
    transform: translate3D(0, 0, 0);
  }

  .fade-enter,
  .fade-leave-active {
    transform: translate3D(100%, 0, 0);
  }


  /* bigimg */

  .img-view {
    position: relative;
    width: 100%;
    height: 100%;
  }

  /*遮罩层样式*/
  .img-view .img-layer {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.7);
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-repeat: no-repeat;
  }
  /*不限制图片大小，实现居中*/
  .img-view .img img {
    /* max-width: 100%; */
    display: inline-block;
    position: absolute;
    top:0;
    bottom: 0;
    left: 30%;
    right: 0;
    margin: auto;
    text-align: center;
    z-index: 1000;
    /* width: 600px */
    height: 400px;
    background-repeat: no-repeat;
  }
</style>